import styled, { css } from 'styled-components'

export const Wrapper = styled.section`
  position: relative;
  margin: 80px 0;
  padding: 0;
  transition: background-color 1s 1s ease;
  ${({ active }) =>
    active
      ? css`
          background-color: white;
        `
      : css`
          background-color: transparent;
        `}

  > div {
    ${({ active }) =>
      active
        ? css`
            filter: blur(0);
            opacity: 1;
          `
        : css`
            filter: blur(5px);
            opacity: 0;
          `}
  }

  ${({ theme }) => theme.media.l} {
    margin: 100px 0;
  }
`

export const Content = styled.div`
  z-index: 2;
  position: relative;
  padding-left: ${({ theme }) => theme.heroPaddings.xxs};

  > h2,
  > h3,
  > h4,
  p {
    margin: 20px auto;
    max-width: 600px;
    padding: 0 30px;
    line-height: 1.66;
    color: black;
    transition: opacity 0.8s ${({ delay }) => (delay ? delay : '2.6')}s ease;
    opacity: ${({ active }) => (active ? '1' : '0')};
  }

  > h2 {
    font-size: 30px;
    font-weight: 700;
  }

  > h3 {
    font-size: 26px;
    font-weight: 700;
  }

  > h4 {
    font-size: 22px;
    font-weight: 700;
  }

  > p {
    font-size: 18px;
    font-weight: 400;
  }
`
