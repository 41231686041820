import React from 'react'
import PropTypes from 'prop-types'
import { Wrapper, Content } from './styles.js'

import HorWrap from '@atoms/HorWrap'
import OrangeHeader from '@atoms/OrangeHeader'
import PurpleHeader from '@atoms/PurpleHeader'

const OnlyText = ({ active, orangeHeader, header, children, delay }) => {
  return (
    <Wrapper active={active}>
      <OrangeHeader active={active} direction="right">
        {orangeHeader}
      </OrangeHeader>
      {header}
      <Content active={active} delay={delay}>
        {children}
      </Content>
    </Wrapper>
  )
}

OnlyText.propTypes = {
  active: PropTypes.bool,
  header: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
}

OnlyText.defaultProps = {
  active: false,
  header: null,
  delay: 2.6,
  children: null,
}

export default OnlyText
