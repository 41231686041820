import React from 'react'
import { graphql } from 'gatsby'
import { useInView } from 'react-intersection-observer'
import Main from '@templates/Main'

import HorWrap from '@atoms/HorWrap'
import PurpleHeader from '@atoms/PurpleHeader'
import OnlyText from '@organisms/OnlyText'

import HeroSection from '@organisms/HeroSection'
import Newsletter from '@organisms/Newsletter'
import PurpleSection from '@organisms/PurpleSection'
import Testimonials from '@organisms/Testimonials'
import VideoFull from '@organisms/VideoFull'
import Team from '@organisms/Team'
import WhatWeDo from '@organisms/WhatWeDo'
import HowWeWork from '@organisms/HowWeWork'
import Work from '@organisms/Work'
import { ReactMarkdown } from 'react-markdown/lib/react-markdown'

const PrivacyPage = ({ data, transitionStatus }) => {
  const pageData = data.datoCmsHomePage
  const privacyData = data.datoCmsPrivacyPolicy
  const [refSecOne, SecOneInView] = useInView({
    threshold: 0,
    triggerOnce: true,
  })

  return (
    <Main
      seo={pageData.seoMetaTags}
      transitionStatus={transitionStatus}
      enterColor="#4100F5"
    >
      <div ref={refSecOne} id="sec-one">
        <br />
        <br />
        <br />
        <br />
        <br />
        <HorWrap>
          <OnlyText
            orangeHeader={`01 Consent`}
            delay={1.4}
            header={
              <PurpleHeader
                active={SecOneInView && transitionStatus === 'entered'}
                delay={1}
                text={privacyData.header.split(' ')}
              />
            }
            active={SecOneInView && transitionStatus === 'entered'}
          >
            <ReactMarkdown children={privacyData.content} />
          </OnlyText>
        </HorWrap>
      </div>
    </Main>
  )
}

export const query = graphql`
  query PrivacyPageQuery {
    datoCmsHomePage {
      seoMetaTags {
        tags
      }
    }
    datoCmsPrivacyPolicy {
      id
      header
      content
    }
  }
`

export default PrivacyPage
